import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/bep20/Banner"
import Whatis from "../components/bep20/Whatis"
import WhyShould from "../components/bep20/WhyShould"
import CoreFeatures from "../components/bep20/CoreFeatures"
import WhyChoose from "../components/bep20/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import PortfolioSection from "../components/nftexchange/Portfolio"
import DevApproach from "../components/bep20/DevApproach"
import TechStack from "../components/bep20/TechStack"
import FaqSection from "../components/bep20/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import DevServices from "../components/bep20/DevService"
import KeyThinks from "../components/bep20/KeyThinks"



const bep20 = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>BEP20 Token Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone, a professional Bep20 token development company offers world-class Binance Smart Chain token creation services at an affordable price." />
        <meta name="keywords" content="Bep20 token development company, Bep20 token development services, Bep20 token development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="BEP20 Token Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone, a professional Bep20 token development company offers world-class Binance Smart Chain token creation services at an affordable price." />
        <meta property="og:url" content="https://www.coinsclone.com/bep-20-token-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/bep-20-token-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone, a professional Bep20 token development company offers world-class Binance Smart Chain token creation services at an affordable price." />
        <meta name="twitter:title" content="BEP20 Token Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/bep-20-token-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/bep-20-token-development/" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | BEP20 Token Development Company</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <DevServices />
      <KeyThinks />
      <WhyChoose />
      <DevApproach />
      <CoreFeatures />
      <Testimonial />
      <PortfolioSection />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default bep20
