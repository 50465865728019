import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      <Container>
        <div className="text-center">
          <h2 className="heading-h2"><span className="bluecolor">Frequently</span> Asked Questions
          </h2>
        </div>
        <Accordion defaultActiveKey={activeId}>
          <div>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is a BEP20 token?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">BEP20 is a popular utility token standard on the Binance Smart chain. This token standard holds certain rules and functionalities a utility token should follow within the BSC network. Depending upon the role, the Binance token can be used as a utility token or a governance token as a medium of providing access or service to the users. Also, BSC tokens are highly secure and multi-functional to meet the desired role effectively.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How Long Does it Take to Develop a BEP20 Token?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Developing a BEP20 token hardly takes more than one to two days if all the preparation work is completed. At Coinsclone, we follow a streamlined development strategy that minimizes development time without compromising quality at any cost. However, the development time can be slightly delayed under certain real-world conditions and technical issues.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Why Choose a BEP20 Development company to create a BEP20 token?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">One of the primary reasons to choose a BEP20 Token Development company is token security and reliability. For instance, if you create a BEP20 token through a freelancer, the outcome may not always be successful and could lead to technical errors. On the other hand, the company can aid in creating a quality-assured Binance token with zero technical faults.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the regulatory considerations in creating a BEP20 token?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">When creating a BEP20 token you should ensure certain laws and regulations are followed correctly. This includes local jurisdiction norms regarding token issuance, Global laws on crypto token security, Implementing necessary security measures like KYC/AML modules, and more. Getting legal guidance from a professional expert is a wise option for a newbie investor.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                Can I implement custom functions in my BEP20 token?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Yes of course! You can discuss your ideology and token functionality requirements with your BEP20 token development company. The developer will execute the necessary customization of the token functionality for specific use cases according to the BEP20 standard.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle" variant="link" eventKey="6">
                How do I integrate my BEP20 token with a DeFi platform?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">With cross-chain compatibility and interoperability, the BEP20 Binance tokens can work with different decentralized applications without any issues. Typically, you can use these Binance smart tokens in DeFi staking, DeFi lending, and borrowing, DeFi yield farming, and other governance and functional purposes of Decentralized Finance platforms.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection