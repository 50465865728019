import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <div className='cryptoex'>
      <section className="usr-admin-tab pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">Explore <span className="bluecolor">Best-in-Class Features</span> in 
            Our BEP20 token Development Services</h2>
            <p className='text-center'>As a renowned BEP20 token development company, Coinsclone offers top-notch features that can upgrade your business standards a step forward.</p>
          <div className="row">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Core Features
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Security Features
                </li>
              </ul>

              <div className="tab-content bep20 px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <h3>Dual-chain Architecture</h3>
                            <p className='pharagraph'>The dual-chain architecture of the Binance smart chain offers significant edges to the BEP20 tokens. This feature enables the token to offer easy interoperability and quicker transaction verification with minimal gas fees.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Cross-chain compatibility</h3>
                            <p className='pharagraph'>With cross-chain compatibility, BEP20 smart tokens can establish better connectivity with various blockchain applications. By keeping the Binance tokens in a basic wallet, the user can switch between the two different blockchain networks effortlessly.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>POS Support</h3>
                            <p className='pharagraph'>BSC networks follow the Proof of Staked Authority (PoSA) consensus algorithm. Therefore the users can utilize BEP20 tokens on respective Decentralized Finance platforms for generating passive income and for voting purposes.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Effortless Listing</h3>
                            <p className='pharagraph'>Our BEP20 token creation services ensure unique quality standards and features for BSC tokens. This simplifies your efforts on listing these Binance Smart Chain Tokens on popular DEXs and probably increases the token sale.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Smart Contract Auditing</h3>
                            <p className='pharagraph'>Extract the potency and performance of BEP20 BSC tokens through periodical smart contract auditing. Our team of experts will conduct rigorous smart contract testing to examine the security and reliability of the smart contract.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Token Minting </h3>
                            <p className='pharagraph'>The minting feature allows the BEP20 tokens standard for creating new tokens. The token holder can mint these Binance tokens to increase the availability, meeting the total supply and demand if necessary.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Token Burning</h3>
                            <p className='pharagraph'>Our BEP20 Binance tokens are built with token-burning features in which the token holder can limit the total token circulation. Burning tokens on the BSC network will help the token owner balance the supply and demand.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Support System</h3>
                            <p className='pharagraph'>Our BEP20 token development services ensure a 24/7 support system that allows the user to raise disputes. With this feature, the admin can assist the user in resolving issues more quickly. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>Stages and Progress Bar</h3>
                            <p className='pharagraph'>The admin can view, add, edit, and access the details about the BEP20 token sale progress in the admin panel. The user can access the details on tokens sold, token balance, etc in the progress bar.</p>
                        </div>
                    </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <h3>End-to-End encryption</h3>
                            <p className='pharagraph'>BEP20 asset transactions are highly protected with advanced encryption algorithms. The algorithm ensures data security by employing top-notch encryption at the starting and finishing end of the data processing.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multilevel SSL security</h3>
                            <p className='pharagraph'>Our BEP20 token development incorporates SSL/TLS protocols to ensure secure and encrypted communication to protect data from tampering and forgery. This feature will protect and enhance the overall security of data in transit.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Device-level Native Security</h3>
                            <p>While creating BEP20 tokens, we integrate security modules that are to be combined with device security features. So token transfer becomes more secure with graded security on the BSC network.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Additional Modules</h3>
                            <p>Our BEP20 cryptos come with essential modules to facilitate the burning, pausing, and minting functionalities. These separate modules will enhance the Binance utility token performance and security incredibly.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multi-chain Supporting Smart Contract</h3>
                            <p className='pharagraph'>We develop BEP20 tokens that support the dual chain architecture of the Binance smart chain. However, the multi-chain supporting smart contract is designed to work with different blockchains to facilitate cross-chain functionality.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Irreversible Tokenization</h3>
                            <p className='pharagraph'>This security feature does not allow the BEP20 cryptocurrency user or holder to reverse the tokenization. This will help to maintain the BSC token parameters like supply, demand, and value in a controlled environment.</p>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}

export default CoreFeatures