import React from "react"

class DevServices extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod gray-bg">
        <div className="container">
          <h2 className="heading-h2 text-center">
          BEP20 Token <span className="bluecolor">Development Services</span>
          </h2>
          <p className="text-center">As a top-notch <a href="https://www.coinsclone.com/crypto-token-development/">Crypto token development company</a>, we offer customized BEP20 token creation services as per your business needs. Have a glance at the services here…</p>
          <div className="d-lg-flex">
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/token-development.png"
                    alt="BEP20 Token Creation"
                  />
                  BEP20 Token Creation
                </div>
                <p className="pharagraph">
                We follow a streamlined BEP20 token development method which is ideal to trace and track the development process. During the development phase, we completely guide you to make multi-compatible BEP20 virtual assets.
                </p>
              </div>
              <div className="square">
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/token-migration.png"
                    alt="BEP20 Token Migration Services"
                  />
                  BEP20 Token Migration Services
                </div>
                <p className="pharagraph">
                Our Binance token migration service is quite helpful in moving a BEP20 Binance token to other compatible blockchains like Ethereum, Tron, etc. We offer high-standard BEP20 token migration services to lead the market competition.
                </p>
              </div>
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/token-listing-on-exchange.png"
                    alt="Listing BEP20 Tokens On Exchange"
                  />
                  Listing BEP20 Tokens On Exchange
                </div>
                <p className="pharagraph">
                Make your BEP20 assets globally popular through our Binance token listing services on crypto exchanges. It is one of the significant areas for your BEP20 BSC tokens to attain a massive reach and better value in the market.
                </p>
              </div>
            </div>
            <div className="d-lg-flex">
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/cold-storage-mechanism.png"
                    alt="BEP20 Token Wallet Development"
                  />
                  BEP20 Token Wallet Development
                </div>
                <p className="pharagraph">
                A promising and highly secured crypto wallet is a primary need to secure and manage the newly created BEP20 tokens. We have expertise in developing secure and compatible blockchain wallets without flaws.
                </p>
              </div>
              <div className="square">
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/token-wallet-development.png"
                    alt="Mintable/Burnable Token Development"
                  />
                  Mintable/Burnable Token Development
                </div>
                <p className="pharagraph">
                The two main characteristics to be defined while creating tokens are the ability to mint and burn. Our BEP20 token creation allows the token holder to increase or decrease the token supply based on market demands.
                </p>
              </div>
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/ethereum/ico-development.png"
                    alt="ICO BEP20 Token Development"
                  />
                  ICO BEP20 Token Development
                </div>
                <p className="pharagraph">
                Coinsclone creates ICO platforms and Binance crowdfunding tokens to initiate fundraising through token sales. We provide unique and personalized development services for creating BEP20 crowd-sale tokens, ICO websites, and whitepaper creation.
                </p>
              </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default DevServices
