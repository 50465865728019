import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class PortfolioSection extends React.Component {

  render() {
    return (
      <div className='pt-100'>
      <section className="portfoliosec mb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"> <span className="heading-h3"><span className="bluecolor">Our Portfolio Powers</span> more than 350<span> <FontAwesomeIcon icon={faPlus} /> </span></span>
              Crypto Startups Globally </h2> </div>
          <div className="quick text-center"> <img className='mb-0'  width="1240px" height="568px" src="https://coinsclone.mo.cloudinary.net/images/home-new/our-portfolio.webp" alt="portfolio" />
          </div>
        </div>
        </div>
      </section>
      </div>
    )
  }
}

export default PortfolioSection