import React from 'react'

class WhyShould extends React.Component {

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">BEP20 Token Development
              </h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="416px" height="413px" src="https://coinsclone.mo.cloudinary.net/images/bep20-new/bsc-token-development.webp" alt="BEP20 Token Development" />
            </div>
            <div className="col-lg-6 col-md-12">
            <p className="pharagraph">BEP20 token development is the process of developing a token on the Binance Smart Chain (BSC) using the BEP20 token standard supporting different use cases. We create BEP20 tokens that leverage the same attributes and functionalities of the ERC20 token but with the edges of the BSC chain. Thus our BEP20 tokens can serve various decentralized platforms with seamless interoperability, speed, and scalability.
              </p>
              <p className="pharagraph mb-0">Our BEP20 token creation process focuses on developing EVM-compatible Binance chain tokens. Thus they can easily interconnect with decentralized applications built on the Binance blockchain. Our BEP20 token development utilizes the maximum edges of the Binance Chain to leverage best-in-class services to a decentralized ecosystem. They can offer quicker and more cost-efficient transactions for different business services and use cases.
              </p>
            </div>
          </div>
          <div className='text-center'>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Hire Our BEP20 Token Developers</a>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould