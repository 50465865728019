import React from "react"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Slider from "react-slick";

class KeyThinks extends React.Component {

  render() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      };

    return (
      <div className="pt-100">
      <section className="we-focus key-thinks">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Key Things </span>to Know About BEP20 Token Development
            </h2>
            <p className="text-center">
            Explore how to extract maximized gains and efficiency with BEP20 token creation by understanding some key elements. Here are the key things you should know.
            </p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="start" transition={false}>
                <Row>
                    <Col xl={4} lg={12} className="p-0 choose-tab">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="start">1. How are BEP20 tokens Unique?</Nav.Link>
                        <Nav.Link eventKey="four">2. Benefits of BEP20 Token Development</Nav.Link>
                        <Nav.Link eventKey="five">3. Industries Our BEP20 Tokens Serve</Nav.Link>
                        </Nav.Item>
                    </Nav>  
                    </Col>
                    <Col xl={8} lg={12} className="contents"> 
                    <Tab.Content>
                        <Tab.Pane eventKey="start" className="sliding"> 
                            <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/benefits-bep20.png" alt="What is BEP20" width={65} height={65} />
                            <h3>How are BEP20 tokens Unique?</h3>
                            <p>BEP20 BSC tokens represent the edges of the Binance smart chain providing higher transaction throughput at minimal transaction fees. Compared to other utility tokens, the BSC token is capable of delivering quicker transaction verification with unprepared performance. These are the key elements keeping them better than other tokens.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <div>
                            <Slider {...settings}>
                                <div className="sliding">
                                    <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/minimal.png" alt="Minimal Transaction Fees" width={65} height={65} />
                                    <h3>Minimal Transaction Fees</h3>
                                    <p>Among the popular blockchains, the Binance Smart Chain network offers lower transaction fees than Ethereum and Tron. So BEP20 BSC token can easily attract global investors to invest through crowdfunding and trading.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/quicker.png" alt="Quicker Transactions" width={65} height={65} />
                                    <h3>Quicker Transactions</h3>
                                    <p>Generally, the BSC blockchain can handle up to hundreds of transactions per second. Accordingly, we create BEP20 tokens to serve quicker transactions that open up newer investment possibilities in Binance chain projects.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/interoperability.png" alt="Interoperability" width={65} height={65} />
                                    <h3>Interoperability</h3>
                                    <p>Our BEP20 Binance tokens are easily interactable with decentralized applications and platforms. With this feature, the BSC token can seamlessly connect with DeFi wallets, dApps, and other services in the Binance Smart Chain.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/crowdfunding-capacity.png" alt="Crowdfunding Capacity" width={65} height={65} />
                                    <h3>Crowdfunding Capacity</h3>
                                    <p>BEP20 tokens are a better alternative to ERC20 tokens as they offer better performance and speed. Generally, investors prefer to invest in such BSC tokens to gain more productivity and profits from their businesses.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/security.png" alt="Security and Reliability" width={65} height={65} />
                                    <h3>Security and Reliability</h3>
                                    <p>Binance Smart Chain is a proven blockchain network with robust security measures offering reliable operation. We create BEP20 tokens with high scalability and security measures that upgrade your business credibility to the next level.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/wider.png" alt="Wider Usage" width={65} height={65} />
                                    <h3>Wider Usage</h3>
                                    <p>Binance utility tokens record higher demand and usage in the cryptocurrency industry. So when you create a BEP20 token, listing and trading the tokens in prominent DEXs becomes more straightforward.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/best-class-liquidity.png" alt="Best-in-class Liquidity" width={65} height={65} />
                                    <h3>Best-in-class Liquidity</h3>
                                    <p>The growth and demand for creating BEP20 tokens are mostly contributed by the popular DEXs (like PancakeSwap) running on the Binance Smart Chain. Thus by default, these BSC tokens have best-in-class liquidity than other utility tokens.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/scalability.png" alt="Scalability" width={65} height={65} />
                                    <h3>Scalability</h3>
                                    <p>Our BEP20 token development service is focused on offering unbeatable scalability to process large-scale transactions. We create BSC tokens that leverage the higher throughput of the Binance smart chain to boost your service offerings.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/innovation.png" alt="Innovation Possibility" width={65} height={65} />
                                    <h3>Innovation Possibility</h3>
                                    <p>BSC smart chain supports a wider range of tools and technology stacks capable of adapting innovations and customization. Our BEP20 token creation services utilize these positive edges in creating future-ready Binance tokens.</p>
                                </div>
                            </Slider>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="five">
                            <Slider {...settings}>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/decentralized-finance.png" alt="Decentralized Finance" width={65} height={65} />
                                    <h3>Decentralized Finance</h3>
                                    <p>BEP20 tokens are the major players in the Decentralized Finance ecosystem built on the BSC smart chain. Our BSC tokens are the functional element in various DeFi services like lending, borrowing, and yield farming.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/gaming.png" alt="Gaming" width={65} height={65} />
                                    <h3>Gaming</h3>
                                    <p>With the lower gas fee benefits, Binance crypto tokens are widely used in Play to Earn gaming platforms for various purposes. We develop BEP20 tokens ideal for contributing to in-game purchases, rewards, and voting rights.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/supply.png" alt="Supply Chain Management" width={65} height={65} />
                                    <h3>Supply Chain Management</h3>
                                    <p>We create BEP20 tokens with top-notch transparency and traceability utilizing the edges of the BSC chain. Accordingly, tracking the goods' movement and real-time monitoring becomes easy with our BSC tokens.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/loyalty.png" alt="Loyalty and Governance" width={65} height={65} />
                                    <h3>Loyalty and Governance</h3>
                                    <p>Platforms built on the BSC chain have community support that contributes to platform growth. Our BEP20 BSC tokens are perfectly viable for providing loyalty rewards and governance rights to contributed users.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/crypto-payments.png" alt="Crypto Payments" width={65} height={65} />
                                    <h3>Crypto Payments</h3>
                                    <p>BEP20 tokens are the major player in Micropayment services which facilitate crypto payments instead of some traditional payments. Our Binance tokens are ideal for payments or remittances with due diligence to security and speed.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/crowdfunding.png" alt="Crowdfunding" width={65} height={65} />
                                    <h3>Crowdfunding</h3>
                                    <p>We create BEP20 tokens that can bear greater market value, and retain better future scope. Hence our smart chain BSC tokens are highly reliable for crowdfunding through ICO, IEO, and IDO fundraising strategies.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/real.png" alt="Real Estates" width={65} height={65} />
                                    <h3>Real Estates</h3>
                                    <p>We develop BEP20 tokens with better utility provisions making them ideal for Asset tokenization. This allows investors to invest in a digital property either fully or fractionally depending upon their financial constraints.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/retail.png" alt="Retail and E-commerce" width={65} height={65} />
                                    <h3>Retail and E-commerce</h3>
                                    <p>With a refined BEP20 token development process, we enable BSC tokens for better governance features. Hence retail and e-commerce businesses can distribute offers and rewards to customers with these BSC tokens.</p>
                                </div>
                                <div className="sliding">
                                <img src="https://coinsclone.mo.cloudinary.net/images/bep20-new/healthcare-industry.png" alt="Healthcare Industry" width={65} height={65} />
                                    <h3>Healthcare Industry</h3>
                                    <p>Our BEP20 tokens completely work in a decentralized manner to offer better data integrity and protection. These qualities are paramount for healthcare sectors to manage patient records, and drug distribution and incentivize wellness programs.</p>
                                </div>
                            </Slider>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
          </div>
      </section>
      </div>
    )
  }
}

export default KeyThinks
